
import {Component, Mixins, Prop} from "vue-property-decorator";
import {IlEntity} from "@/entity/IlEntity";
import {IcraDairesiEntity} from "@/entity/IcraDairesiEntity";
import IcraDairesiList from "@/components/lists/IcraDairesiList.vue";
import {IlceEntity} from "@/entity/IlceEntity";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";

@Component({
  components: {IcraDairesiList},
})
export default class IcraDairesiPicker extends Mixins(PickerInputMixin) {
  @Prop() rules!: any;
  @Prop({default:'İcra Dairesi Seçiniz'}) label !: string;
  @Prop() placeHolder !: string;
  dialog = false;
  items: Array<IcraDairesiEntity> = [];

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/icra-dairesi/").then((response) => {
      this.items = response.data;
      this.selectedItem = this.items.filter((item: IcraDairesiEntity) => item.id == this.selectedId)[0];
      this.input();
      this.items.unshift(<IcraDairesiEntity>{
        isim: "Ara..",
        id: -1,
        sehir: new IlEntity(),
        ilce: new IlceEntity(),
        vergi_no: "",
      });
    });
  }

  onSelectedItemChanged() {
    if (this.selectedItem && this.selectedItem.id < 0) {
      this.dialog = true;
    } else {
      this.selectedId = this.value;
      this.input();
    }
  }

  onIcraDairesiActivated(selectedIcraDairesi: IcraDairesiEntity) {
    this.selectedId = selectedIcraDairesi.id;
    this.load();
  }

  onActivationCancelled() {
    this.onValueChange();
  }

  clearSelectedItem() {
    this.selectedItem = null;
  }
}
