
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import TakipDurumuPicker from "@/components/pickers/TakipDurumuPicker.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import KararNumarasi from "@/components/inputs/KararNumarasi.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {
    TakipTipiPicker,
    KararNumarasi,
    TcKimlikNumarasi,
    VergiNumarasi,
    IcraDairesiPicker,
    TakipDurumuPicker,
    TelefonNumarasi,
    DosyaEsasNumarasi
  }
})
export default class TakipListFilterForm extends Mixins(SimpleInputMixin) {
  filters: any = {
    borcluAdSoyad: null,
    borcluTcKimlik: null,
    alacakliTcKimlik: null,
    borcluVergiKimlik: null,
    alacakliVergiKimlik: null,
    icraDairesi: null,
    dosyaEsasNo: null,
    alacakliAdSoyad: null,
    takipDurumu: null,
    ilamEsasNo: null,
    ilamKararNo: null,
    kartNo: null,
    icraProKartNo: null,
    mtsEsasNo: null,
    takipTipi: null,
    keyword: null
  };

  sifirla() {
    this.localValue = {...this.filters};
    this.input();
    this.$emit('search');
  }
}
